<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="false" />
    <v-layout wrap>
      <v-flex xs12 md12 pa-0>
        <v-card id="app_height" outlined tile>
          <v-layout wrap justify-space-around>
            <v-flex xs12 md12 pa-8>
              <v-layout wrap>
                <v-flex xs12 md12 text-center>
                  <span style="font-size:20px;font-weight:500;">Application Company Users List</span>
                </v-flex>
              </v-layout>
              <v-layout wrap px-2 justify-center>
                <v-flex xs12 md12>
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      color="#26af82"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>

                  <v-data-table
                    :headers="headers"
                    dense
                    :items="logs"
                    :search="search"
                    :sort-desc="[false, true]"
                    multi-sort
                    color="#26af82"
                    :loading="appLoading"
                    loading-text="Loading... Please wait"
                    class="elevation-1"
                  >
                    <template v-slot:item.address="{ item }">
                      <span v-if="item.address">{{item.address.address}},</span>
                      <span v-if="item.address">{{item.address.city}}</span>
                      <span v-if="item.address">{{item.address.postcode}}</span>
                    </template>
                    <template v-slot:item.phone="{ item }">
                      <span>{{item.phonePrefix}}</span>
                      <span>{{item.phone}}</span>
                    </template>
                    <template v-slot:item._id="{ item }">
                      <router-link :to="'/ViewUser/'+item._id">
                        <v-btn small text color="#26af82">View</v-btn>
                      </router-link>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import VueElementLoading from "vue-element-loading";
import axios from "axios";
export default {
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      msg: null,
      search: null,
      headers: [
        {
          text: "Username",
          align: "left",
          sortable: true,
          value: "username"
        },
        { text: "Type", value: "type" },
        { text: "Phone", value: "phone" },
        { text: "Email ID", value: "email" },
        { text: "Address", value: "address" },
        { text: "Action", value: "_id" }
        // { text: "Source", value: "source", filterable: false }
      ],
      logs: []
    };
  },
  beforeMount() {
    this.getLogs();
  },
  methods: {
    getLogs() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/allCompanys",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        }
      })
        .then(response => {
          this.logs = response.data.data;
          this.appLoading = false;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Something Went Wrong";
        });
    }
  }
};
</script>
<style>
@media only screen and (min-width: 800px) {
  #app_height {
    min-height: 80vh;
    min-width: 80vw;
  }
}
</style>